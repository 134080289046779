import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HeroSection from './components/HeroSection';
import Loader from '../../components/Loader';
import SpondonHomePage from './spondon/SpondonHomePage';
import CustomHome1 from './customTemplate1/CustomHome1';
import { includes } from 'lodash';


export default function HomePage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      const url = currentUser.role === 'student' ? `/learn/home` : `/services`;
      navigate(url);
    }
    setLoading(false);
  }, []);


  if (loading) {
    return (
      <Loader />
    )
  }

  // if (currentInstitute?.subdomain === 'spondon') {
  //   return (
  //     <SpondonHomePage currentInstitute={currentInstitute} />
  //   )
  // } else 

  if (includes(['spondon', 'joykoli', 'vitez', 'demo-dev', 'scholars'], currentInstitute?.subdomain)) {
    return <CustomHome1 currentInstitute={currentInstitute} />
  }

  return (
    <div>
      <HeroSection currentInstitute={currentInstitute} />
    </div>
  )
};

