import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { omit, isEmpty, capitalize, cloneDeep, findIndex } from 'lodash';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import {
  getPrograms,
  changeProgramStatus
} from '../../services/program';

const headers = ['Name (English)', 'Name (Bangla)', 'Payment circle', 'Code', 'Status'];
const filters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      { label: 'Active', value: 'active', defaultChecked: true },
      { label: 'Inactive', value: 'inactive' },
    ]
  }
];

export default function ProgramsPage() {
  const [programs, setPrograms] = useState([]);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [statusFilters, setStatusFilters] = useState(['active']);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    }
    _fetchData();
  }, [
    queryString,
    currentPage,
    statusFilters
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        limit: 10,
        page: currentPage,
        sortBy: 'name',
        search: queryString
      };

      console.log(statusFilters);

      if (statusFilters.length > 0) {
        params.status = statusFilters;
      }

      console.log(statusFilters, params);

      const data = await getPrograms(params);
      setPrograms(data.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, _queryString) => {
    event.preventDefault();
    const q = !isEmpty(_queryString) ? _queryString : null;
    setQueryString(q);
    setCurrentPage(1);
    setLoading(true);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const fieldName = filters[filterIndex].fieldName;

    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    }
  };

  const handleStatusChange = async (event) => {
    try {
      event.preventDefault();
      await changeProgramStatus(selectedProgram._id);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      title="Programs"
      subTitle={`List of programs for your institution.`}
      filters={filters}
      onFilterChange={applyFilterChanges}
      onCreate={() => window.location.href = window.location.href = `/programs/create`}
    >
      {showStatusModal &&
        <ConfirmModal
          title={selectedProgram?.status === 'active' ? 'Disable program' : 'Enable program'}
          description={`Are you sure to ${selectedProgram?.status === 'active' ? 'disable' : 'enable'} this program? Please double check before performing this action.`}
          actionName={selectedProgram?.status === 'active' ? 'Disable' : 'Enable'}
          onConfirm={handleStatusChange}
          onCancel={() => {
            setSelectedProgram(null);
            setShowStatusModal(false);
          }}
        />}

      <Table
        headers={headers}
        items={programs}
        itemsCount={programs.length}
        queryString={queryString}
        pagingData={pagingData}
        onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {programs.map((program) => (
          <tr key={program._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {program.name}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {program.nameBN}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {program.paymentCircle}
            </td>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {program.programRollCode || '-'}
            </td>
            <td
              className={`px-3 py-4 text-sm ${program.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(program.status)}
            </td>

            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems
                program={program}
                onStatusChange={() => {
                  setSelectedProgram(program);
                  setShowStatusModal(true);
                }}
              />
            </td>
          </tr>
        ))}
      </Table>
    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const {
    program,
    onStatusChange
  } = props;

  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" href={`/programs/${program._id.toString()}/edit`} />
      </div>
      <div className="py-1">
        <MenuItem label="Manage exams" href={`/programs/${program._id.toString()}/exams`} />
      </div>
      <div className="py-1">
        <MenuItem label={program?.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
      </div>
    </ActionMenu>
  )

};
